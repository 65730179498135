import { GetStaticProps } from 'next';
import PublicContentService from '~/api/services/publicContentService';
import WfaPage, { WfaPageProps } from '~/components/pages/WfaPage';

export const getStaticProps: GetStaticProps<WfaPageProps> = async () => {

  const [competitions] = await Promise.all([
    // don't read ALL full competition objects! that's a time drain. instead, get the the synopsis first, figure out what
    // competition you'd like to focus on, and then get the full single competition object. even with 3 competitions
    // the savings is 66%.... in the future we'll have more.
    PublicContentService.getCompetitionSynopsis(),
  ]);
  const now = new Date().getTime();
  const eligibleCompetitions = [...competitions.present, ...competitions.future, ...competitions.past]
    .filter(({ timeline: { entries_begin_on } }) => new Date(entries_begin_on).getTime() < now)
    .sort(
      ({ timeline: { entries_begin_on: left } }, { timeline: { entries_begin_on: right } }) =>
        -1 * left.localeCompare(right)
    );
  if (eligibleCompetitions.length === 0) return { notFound: true };

  // get the full competition object we need. 
  const compObject = await PublicContentService.getCompetition(eligibleCompetitions[0].id);
  return {
    props: {
      activeCompetition: compObject
    },
    revalidate: 30,
  };
};

export default WfaPage;
