import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import LSTVButton from '~/components/LSTVButton';

const ReadMoreButton = styled(LSTVButton)`
  font-size: 17px;
  line-height: 19px;
  display: flex;
  align-items: center;
  background: transparent;
  cursor: pointer;
`;

const ReadMoreButtonIconContainer = styled.div<{ $expanded: boolean }>`
  width: 21px;
  height: 21px;
  padding-left: 2px;
  border-radius: 100px;
  box-shadow: 0px 0px 1px #707070;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  margin-left: 10px;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  ${(props) => props.$expanded && `transform: rotate(90deg);`}
`;

/**
 * A button that expands/collapses a container.
 */
const useReadMoreButton = (caption: string = 'Read More') => {
  const [expanded, setExpanded] = React.useState(false);
  const [contentHeight, setContentHeight] = React.useState(0);
  return {
    /**
     * Container height should be set to this number if you need to animate
     * height (CSS transitions don't work with height auto).
     */
    containerHeight: expanded ? contentHeight : 0,
    /**
     * Should be passed to ref prop of the container.
     */
    setContainerEl: (el: HTMLElement) => {
      if (el) {
        setContentHeight(el.scrollHeight);
      }
    },
    readMoreButton: (
      <ReadMoreButton onClick={() => setExpanded(!expanded)}>
        {caption}
        <ReadMoreButtonIconContainer $expanded={expanded}>
          <FontAwesomeIcon icon={faChevronRight} />
        </ReadMoreButtonIconContainer>
      </ReadMoreButton>
    ),
  };
};

export default useReadMoreButton;
