import { IJudge } from '@lstv/core/types';
import React from 'react';
import styled from 'styled-components';
import LSTVLink from '../LSTVLink';

const ThumbnailContainer = styled.div`
  position: relative;
  min-width: 0;

  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
`;

const NameContainer = styled.div`
  font-size: 21px;
  line-height: 25px;
  color: ${(props) => props.theme.grey_1_0};
`;

const OrganizationContainer = styled(LSTVLink)`
  font-size: 17px;
  line-height: 20.23px;
  color: inherit !important;
  text-decoration: none;
`;

const TextContainer = styled.div`
  color: ${(props) => props.theme.grey_1_0};
  font-size: 15px;
  line-height: 17.85px;
`;

const Separator = styled.div`
  border-top: 1px solid ${(props) => props.theme.midGrey};
`;

const Judge = ({ judge }: { judge: IJudge }) => (
  <div style={{ marginBottom: '16px' }}>
    <ThumbnailContainer>
      <img src={judge.headshot} />
    </ThumbnailContainer>
    <NameContainer style={{ marginTop: '10px' }}>{judge.name}</NameContainer>
    <OrganizationContainer to={judge.achievement_link}>{judge.achievement}</OrganizationContainer>
    <TextContainer style={{ marginTop: '6px' }}>{judge.description}</TextContainer>
  </div>
);

const Judges = ({ judges }: { judges: IJudge[] }) => (
  <>
    {judges.map((judge) => (
      <Judge key={judge.id} {...{ judge }} />
    ))}
  </>
);

export default Judges;
