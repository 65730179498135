import { IQuestion } from '@lstv/core/types/IFaq';
import React from 'react';
import styled from 'styled-components';
import { ICompetition } from '@lstv/core/types';
import { FaqQuestion } from '~/components/FaqQuestions';
import LSTVLink from '~/components/LSTVLink';
import { maxWidth, minWidth } from '~/styles/media';

const faqs: IQuestion[] = [
  {
    q: 'Who may enter the awards?',
    a:
      'Entrants must have at least five videos uploaded to lovestoriestv.com. Employees and direct family members of Love Stories TV are not eligible to enter or win prizes.',
  },
  {
    q: 'Is there a fee to enter the awards?',
    a:
      'No. Filmmakers can enter for free, as well as upload, host, and store unlimited wedding videos for free on lovestoriestv.com.',
  },
  {
    q: 'How do I enter the awards?',
    a:
      'Upload your film(s) via your dashboard on lovestoriestv.com. Please note that this does not enter you into the awards. You must complete the following steps: If you do not yet have a free account on lovestoriestv.com please create one, then follow the instructions on your dashboard for entry.',
  },
  {
    q: 'When will the winners be announced?',
    a: 'Winners will be announced during a virtual awards event. More details and awards show date to come!',
  },
  {
    q: 'Can I enter if I live outside the United States?',
    a: 'Absolutely, you may enter regardless of your geographic location.',
  },
  {
    q: 'How are the entries judged?',
    a: 'Winners are chosen either by a panel of judges or by votes of viewers, depending on the category.',
  },
  {
    q: 'What if my video does not have 5 vendors tagged?',
    a:
      'Our team will work with you to help ensure you can achieve 5 tagged vendors. Contact videos@lovestoriestv.com for details.',
  },
  {
    q: 'What is Love Stories TV?',
    a:
      "<p>Love Stories TV stands at the forefront of the global wedding industry as the premier video platform dedicated exclusively to weddings. Our mission? To seamlessly connect talented videographers like you with soon-to-be-weds and wedding enthusiasts. We do this by showcasing a curated collection of heartwarming and genuine wedding films in our extensive library.</p>" +

      "<p>By featuring your work on lovestoriestv.com, you tap into a unique opportunity to reach a vast audience of engaged couples actively seeking professionals for their big day. Your films won't just be another addition to our platform; they'll be a key part of the inspiration and decision-making process for countless couples.</p>" + 
      
      "<p>But the benefits don't stop there. Publishing with us also means eligibility for features across our social media channels, which boast over 8 million followers. This exposure amplifies your reach, connects you with a broader clientele, and significantly boosts your brand's visibility and growth. In short, Love Stories TV isn't just a platform; it's a gateway to elevating your business in the wedding videography industry.</p>",
  },
  {
    q: 'How can I benefit from having my work on lovestoriestv.com?',
    a:
      'Every month millions of soonlyweds and their families use our site and social channels to find pros, products, ideas, and inspiration for their weddings. Publishing your work on lovestoriestv.com connects you with this huge audience of potential customers.',
  },
];

const PromotionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 20px;
  background: ${(props) => props.theme.highlight_2_0};
  color: ${(props) => props.theme.highlight_1_0};
  border-radius: 10px;

  @media ${maxWidth('sm')} {
    flex-direction: column;
    text-align: center;
  }
`;

const PromotionHeading = styled.div`
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 5px;
`;

const PromotionContent = styled.div`
  flex: 1 1 auto;
  max-width: 450px;
  font-size: 15px;
  padding-bottom: 10px;

  @media ${maxWidth('sm')} {
    margin-top: 15px;
  }

  @media ${minWidth('sm')} {
    margin-left: 45px;
  }
`;

const PromotionLink = styled(LSTVLink)`
  font-weight: 600;
  color: inherit !important;
`;

const Faq = ({ activeCompetition }: { activeCompetition?: ICompetition }) => {
  return (
    <>
      {faqs.map((question, index) => (
        <FaqQuestion key={index} {...{ question }} id={`${index}`} newStyle />
      ))}
      {activeCompetition && (
        <PromotionContainer>
          <img height="109" width="109" src={activeCompetition.competition_badge_primary_3} />
          <PromotionContent>
            <PromotionHeading>Do you have further questions?</PromotionHeading>
            Email the Love Stories TV team at{' '}
            <PromotionLink to="mailto:videos@lovestoriestv.com">videos@lovestoriestv.com</PromotionLink> and we will get
            back to you within 72 hours.
          </PromotionContent>
        </PromotionContainer>
      )}
    </>
  );
};

export default Faq;
