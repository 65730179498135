import { useMemo } from 'react';
import styled from 'styled-components';
import VideoPlayback from '~/components/VideoPlayer/VideoPlayback';

const Container = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
`;

const Video = ({
  media_id,
  type,
  isAutoPlay,
  isMuted,
  withPreRoll = true,
  ...rest
}: { media_id: string; type: string; withPreRoll?: boolean, isAutoPlay?: boolean, isMuted?: boolean } & Omit<
  React.HTMLAttributes<HTMLDivElement>,
  'children'
>) => {
  const playerId = useMemo(() => Math.random().toString(36), []);
  const videoId = useMemo(() => Math.random().toString(36), []);
  return (
    <Container {...rest}>
      <VideoPlayback
        uniqueId={playerId}
        videoType={type}
        videoWidth={'100%'}
        mediaID={media_id}
        videoIdentifier={videoId}
        isMuted={isMuted || false}
        withPreRoll={withPreRoll}
        showLoadingMessage={false}
        isAutoPlay={isAutoPlay || false}
      />
    </Container>
  );
};

export default Video;
