import { ICompetition } from '@lstv/core/types'

import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAuthService } from '~/api/hooks/useAuthService'
import Typography from '~/atoms/Typography'
import { CtaButton, CtaColorScheme, CtaLink } from '~/components/cta/regular'
import Video from '~/components/Markdown/Video'
import { CustomHead } from '~/components/Head'
import ScrollIntoViewContext from '~/components/ScrollIntoViewContext'
import getScrollTabs, { TabButtons, TabPanel } from '~/components/ScrollTabs'
import { TabButtonsControlTab } from '~/components/TabButtonsControl'
import Faq from '~/components/wfa/Faq'
import Judges from '~/components/wfa/Judges'
import Press from '~/components/wfa/Press'
import Rules from '~/components/wfa/Rules'
import Terms from '~/components/wfa/Terms'
import centerLogoWfa from '~/images/Center_LogoWFA.svg'
import aboutImage from '~/images/wfa-hero.gif'
import wfaInviteLG from '~/images/wfa-invite-lg.jpg'
import wfaInviteMD from '~/images/wfa-invite-md.jpg'
import wfaInviteSM from '~/images/wfa-invite-sm.jpg'
import wfaWinnersVideo from '~/images/wfa22winners.png'
import { maxWidth, Media, minWidth } from '~/styles/media'
import theme from '~/styles/styledComponentsTheme'
import Category from './Category'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import CompetitionResults from '../CompetitionResults'
import { Flex } from '~/utils/LSTVUtils'
import VideoPlayback from '~/components/VideoPlayer/VideoPlayback'

export interface WfaPageProps {
  activeCompetition: ICompetition;
}

const HeaderContainer = styled.div`
  background: ${(props) => props.theme.highlight_2_0};
  color: ${(props) => props.theme.highlight_1_0};
  display: flex;
  flex-direction: column;
  max-width: 1348px;

  @media ${maxWidth('md')} {
    padding-top: 16px;
    padding-bottom: 35px;
    flex-direction: column;
  }

  @media ${minWidth('md')} {
    padding-top: 46px;
    padding-bottom: 46px;
  }
`

const HeaderBody = styled.div`
  display: flex;
  flex-direction: column;

  @media ${maxWidth('md')} {
    align-items: center;
  }

  @media ${minWidth('md')} {
    //flex: 1 1 0;
    justify-content: center;
  }
`

const HeaderDescription = styled.div`
  font-size: 19px;
  line-height: 26px;

  @media ${minWidth('sm')} and ${maxWidth('md')} {
    max-width: 80%;
  }

  @media ${maxWidth('md')} {
    margin-top: 20px;
    text-align: center;
  }

  @media ${minWidth('md')} {
    margin-top: 30px;
  }
`

const LogoImage = styled.div<{ $url: string }>`
  background-color: currentColor;
  width: 302px;
  height: 110px;
  mask: url(${(props) => props.$url}) no-repeat center;
`

const ContentContainer = styled.div`
  margin: 0 16px 16px;

  @media ${minWidth('md')} {
    margin: 0 46px 46px;
  }
`

const Badge = styled.div<{ $size: string }>`
  background-color: ${(props) => props.theme.highlight_1_0};
  width: ${(props) => props.$size};
  height: ${(props) => props.$size};
  // Actually this svg already has the right color, but using a mask just for
  // consistency.
  mask: url(https://cdn.lovestoriestv.com/ext/wfa/2024/WFA_00_Badge_Primary_00.svg) no-repeat center;
  mask-size: contain;
`

const CategoriesContainer = styled.div`
  display: grid;
  align-items: start;
  margin-top: 21px;

  @media ${maxWidth('sm')} {
    overflow-x: auto;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
    grid-auto-columns: 311px;
    grid-auto-flow: column;
  }

  @media ${minWidth('sm')} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${maxWidth('md')} {
    grid-gap: 16px;
  }

  @media ${minWidth('md')} {
    grid-gap: 25px;
  }
`

const JudgesContainer = styled.div`
  display: grid;
  align-items: start;
  margin-top: 21px;

  @media ${maxWidth('sm')} {
    overflow-x: auto;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
    grid-auto-columns: 311px;
    grid-auto-flow: column;
  }

  @media ${minWidth('sm')} and ${maxWidth('md')} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${minWidth('md')} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media ${maxWidth('md')} {
    grid-gap: 16px;
  }

  @media ${minWidth('md')} {
    grid-gap: 25px;
  }
`

const FaqContainer = styled.div`
  display: grid;
  align-items: start;
  margin-top: 21px;

  @media ${minWidth('sm')} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${maxWidth('md')} {
    grid-gap: 16px;
  }

  @media ${minWidth('md')} {
    grid-gap: 25px;
  }
`

const StyledTabButtonsControl = styled(TabButtons)`
  @media ${maxWidth('sm')} {
    margin-left: -16px;
    margin-right: -16px;
  }
`

const RulesTabPanel = styled(TabPanel)`
  background-color: ${(props) => props.theme.highlight_2_0};

  @media ${maxWidth('sm')} {
    padding-bottom: 15px;
  }

  @media ${minWidth('sm')} and ${maxWidth('md')} {
    padding-bottom: 20px;
  }

  @media ${minWidth('md')} {
    padding-bottom: 30px;
  }
`

const About = styled.div`
  display: flex;

  @media ${maxWidth('md')} {
    flex-direction: column;
    margin-top: 40px;
    text-align: center;
  }

  @media ${minWidth('md')} {
    margin-top: 70px;
  }
`

const AboutBody = styled.div`
  @media ${maxWidth('md')} {
    padding-bottom: 55px;
  }

  @media ${minWidth('md')} {
    flex: 1 1 0;
    padding: 33px 0;
  }

  > h2 {
    @media ${maxWidth('sm')} {
      font-size: 50px;
      line-height: 48px;
      margin: auto;
    }

    @media ${minWidth('sm')} and ${maxWidth('md')} {
      font-size: 68px;
      line-height: 61px;
      margin: auto;
    }

    @media ${minWidth('md')} {
      font-size: 78px;
      line-height: 81px;
    }
  }
`

const AboutDescription = styled.div`
  font-size: 19px;
  line-height: 26px;
  margin-top: 30px;

  @media ${maxWidth('md')} {
    text-align: center;
  }
`

const AboutImageOuterContainer = styled.div`
  position: relative;
  margin-bottom: 20px;

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media ${maxWidth('md')} {
    height: 411px;
  }

  @media ${minWidth('md')} {
    flex: 1 1 0;
    margin-left: 25px;
  }
`

const InvitationContainer = styled.div`
  position: relative;
  margin-top: 40px;
  background-image: url(${wfaInviteSM.src});
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: calc(100% * 9 / 16 + 20px);

  @media ${minWidth('md')} {
    background-image: url(${wfaInviteMD.src});
    height: 100%;
    background-position: right 40px top 0;
    background-size: auto;
    padding-top: 0;
  }
  @media ${minWidth('lg')} {
    background-image: url(${wfaInviteLG.src});
    height: 512px;
    background-position-x: left;
  }
`


const WinnerVideoContainer = styled.div`
  position: relative;
  margin-top: 40px;
  max-width: 75%;
  width: 75%;
  margin: 30px auto 15px auto;

`

const CursiveHeadline = styled.div`
  font-size: 3rem;
  font-family: 'Dancing Script', cursive;

`;

const InvitationCardHolder = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  @media ${minWidth('lg')} {
    top: 20px;
    right: 120px;
    position: absolute;
  }
`

const InvitationCard = styled.div`
  padding: 25px;
  border-radius: 5px;
  margin-bottom: 20px;
  background: ${theme.grey_5_0};

  @media ${minWidth('md')} {
    width: 550px;
  }
  @media ${minWidth('lg')} {
    background: white;
  }
`

const PastWinnersContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
  @media ${maxWidth('md')} {
    justify-content: center;
  }
`;

const ScrollTabs = getScrollTabs(
  `categories`,
  // Adjust for the height of page header and tab buttons.
  `-${75 + 76}px 0px 0px 0px`,
)

const BreakOutContainerOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BreakOutContainerInner = styled.div`
  box-sizing: content-box;
  max-width: 100%;
  padding: 0 16px;

  @media ${minWidth('md')} {
    padding: 0 46px;
  }

  @media ${theme.breakpoints.laptopL} {
    max-width: 1348px;
    margin: 0 auto;
  }
`

const VideoContainer = styled.div`
  width: 100%;
  @media ${minWidth('lg')} {
    display: flex;
    gap: 20px;
  }
`;

const HeaderVideo = styled(Video)`
  max-width: 800px;
  padding-top: 0 !important;
  margin-bottom: 20px;
  
  > div {
    position: inherit;
  }
`;

const HeaderText = styled.div`
  @media ${minWidth('lg')} {
    max-width: 500px;
    font-size: 1.5rem;
    line-height: 150%;
  }
`;

const SponsorsImage = styled.img`
  margin-top: 30px;
  max-width: 558.5px;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
`;

/**
 * The purpose of this component is to fill left/right margins with background
 * color even on very wide viewports.
 */
const BreakOutContainer = ({
                             backgroundColor,
                             children,
                             ...rest
                           }: { backgroundColor: string } & React.HTMLAttributes<HTMLDivElement>) => (
  <BreakOutContainerOuter {...rest}>
    <div
      style={{
        width: `100vw`,
        backgroundColor,
      }}
    >
      <BreakOutContainerInner>{children}</BreakOutContainerInner>
    </div>
  </BreakOutContainerOuter>
)

const DetailsButton: React.FC<Pick<WfaPageProps, 'activeCompetition'>> = ({ activeCompetition }) => {
  const { loggedIn } = useAuthService()
  const nowTime = new Date().getTime()

  const year = activeCompetition.year ?? activeCompetition.slug.substring(0, 4)
  let url = loggedIn ? `/dashboard/upload-video` : '/sign-up?type=pro'
  let caption = undefined
  let url2 = undefined
  let caption2 = undefined
  if (new Date(activeCompetition.timeline.entries_end_on).getTime() > nowTime) caption = 'Enter Now'
  if (new Date(activeCompetition.timeline.winners_announced_on).getTime() < nowTime) {
    caption = 'See The Winners'
    url = `/wedding-film-awards/${year}/winners`
  } else if (new Date(activeCompetition.timeline.voting_begins_on).getTime() < nowTime) {
    caption = 'See Finalists'
    url = `/wedding-film-awards/${year}/finalists`
    // caption = 'RSVP for Awards Show'
    // url =`https://docs.google.com/forms/d/e/1FAIpQLSfJyiqu7psUUPol3IJvf5UdKOAtBl5nIn6qqo7Gmt_Rg46Y6Q/viewform?usp=sf_link`

    if (new Date(activeCompetition.timeline.voting_ends_on).getTime() > nowTime) {
      caption2 = 'Cast Your Vote'
      url2 = `/wedding-film-awards/vote`
    }
  }

  if (!caption) return null
  return (
    <Flex direction='row' align='center' justifyContent='flex-start'>
      <CtaLink to={url} caption={caption} $colorScheme={CtaColorScheme.Outline} style={{ marginTop: '30px' }} />
      {caption2 && <div style={{ width: '20px' }} />}
      {caption2 && (
        <CtaLink to={url2} caption={caption2} $colorScheme={CtaColorScheme.Outline} style={{ marginTop: '30px' }} />
      )}
    </Flex>
  )
}

const WfaPage = ({ activeCompetition }: WfaPageProps) => {
  const { loggedIn } = useAuthService()
  const ctaUrl = `https://www.bigmarker.com/love-stories-tv/2022-Wedding-Film-Awards`
  const { meta_title, meta_thumbnail, meta_description } = activeCompetition

  const [clickedWinnersPlay, setClickedWinnersPlay] = useState(false)
  useEffect(() => {
    (async () => {
    })()
  }, [clickedWinnersPlay])


  let tabs: TabButtonsControlTab[] = [
    { label: `Categories`, id: `categories` },
    { label: `Rules`, id: `rules` },
    { label: `FAQ`, id: `faq` },
    { label: `Terms & Conditions`, id: `terms` },
    { label: `Judges`, id: `judges` },
    { label: `Past Years Winners`, id: `past-winners` }
  ]

  let seeFinalists = false
  let seeWinners = false
  const nowTime = new Date().getTime()
  if (new Date(activeCompetition.timeline.voting_begins_on).getTime() < nowTime) seeFinalists = true
  if (new Date(activeCompetition.timeline.winners_announced_on).getTime() < nowTime) seeWinners = true

  if (seeFinalists) tabs.push({ label: `Finalists`, id: `finalists` })
  if (seeWinners) tabs.push({ label: `Winners`, id: `winners` })
  console.log(seeWinners)

  return (
    // The two divs below are just to make the dark hero area reach to the sides
    // of the page on very large screens.
    <>
      <CustomHead title={meta_title} titleOg={meta_title} description={meta_description} image={meta_thumbnail} />
      <BreakOutContainer backgroundColor={theme.highlight_2_0}>
        <HeaderContainer>
          <div>
            {/*<CursiveHeadline>The 7th Annual Wedding Films Award<br/><br/><br/></CursiveHeadline>*/}
          <LogoImage $url={activeCompetition.competition_logo_primary} aria-label={activeCompetition.name} />
            <br/>
          </div>
          <VideoContainer>
            <HeaderVideo media_id="B856ibsC" type="jwplayer" withPreRoll={false} />
            <HeaderText>
              Love Stories TV invites the world&apos;s most visionary wedding filmmakers to share the
              epic love stories they have captured this year by submitting selected work to our
              Love Stories TV Awards. Now in its eighth year, the Love Stories TV Awards showcases excellence
              in the wedding film industry. This year, we will award more than $10,000 in cash prizes
              across 18 categories.
              <a href="https://www.bhphotovideo.com/c/browse/sony-digital-photography/ci/5809" target="_blank" rel="noreferrer">
                <SponsorsImage src="/images/wfa-2024-sponsors-white-h.png" alt="Love Stories TV Awards 2024 Sponsors" />
              </a>
            </HeaderText>
          </VideoContainer>
          <HeaderBody>
            {/*<Media greaterThanOrEqual='md'>*/}
              {/*<LogoImage $url={activeCompetition.competition_logo_primary} aria-label={activeCompetition.name} />*/}

            {/*</Media>*/}

            {/*<Media lessThan='md'>*/}
            {/*  <div style={{ marginTop: `30px` }} />*/}
            {/*  <Image src={centerLogoWfa} />*/}
            {/*</Media>*/}
            {/*<HeaderDescription>{activeCompetition.header_paragraph_1}</HeaderDescription>*/}
            {/*<HeaderDescription>*/}
            {/*  <p style={{ margin: '25px 0' }}>*/}
            {/*    RSVP to join us in celebrating the finalists and winners of the 2023 Love Stories TV Awards. Tune in Live on*/}
            {/*    May 10, 2023 at 6pm EST to watch the the awards show being streamed on LIVE TV! Don&apos;t miss an evening of*/}
            {/*    industry recognition and excellence as we raise a glass to our finalists and winners.<br/><br/> Your Awards party To-Do&apos;s:*/}
            {/*  </p>*/}
            {/*  <ol style={{paddingLeft: '1rem', listStyle: 'auto', listStylePosition: 'outside'}}>*/}
            {/*    <li style={{paddingLeft: '0.8rem'}}>Download the Sling app for free on your TV <a href="" rel="noreferrer" target="_blank">https://www.sling.com/freestream</a></li>*/}
            {/*    <li style={{paddingLeft: '0.8rem'}}>Put your champagne on ice!</li>*/}
            {/*    <li style={{paddingLeft: '0.8rem'}}>Go to Sling Freestream</li>*/}
            {/*    <li style={{paddingLeft: '0.8rem'}}>Find the Love Stories TV Channel</li>*/}
            {/*    <li style={{paddingLeft: '0.8rem'}}>Get ready to Celebrate with us!</li>*/}
            {/*  </ol>*/}
            {/*</HeaderDescription>*/}
            <DetailsButton activeCompetition={activeCompetition} />
          </HeaderBody>
          {/*<Media greaterThanOrEqual='md'>*/}
          {/*  {(className) => <div {...{ className }} style={{ flex: '0 0 46px' }} />}*/}
          {/*</Media>*/}
          {typeof window === 'undefined' && (
            <div style={{ flex: '2 2 0', cursor: clickedWinnersPlay ? 'default' : 'pointer' }}>
              {!seeWinners && <Media greaterThanOrEqual='md'>
                <Badge $size='300px' />
              </Media>
              }

              <Video media_id="QIlrNlDw" type="jwplayer" withPreRoll={false} />
              {seeWinners && clickedWinnersPlay && (
                <Video media_id='gDot60Yl' type='jwplayer' isMuted={false} isAutoPlay={true} withPreRoll={false} />)}
              {seeWinners && !clickedWinnersPlay && (
                <Image src={wfaWinnersVideo} onClick={() => setClickedWinnersPlay(true)} />)}

            </div>
          )}
        </HeaderContainer>
      </BreakOutContainer>


      {/* <InvitationContainer>
        <InvitationCardHolder>
          <InvitationCard>
            <Typography variant="h3">Save Your Seat 2022 Awards Party</Typography>
            <p style={{ margin: '25px 0' }}>
              Join us for our best party yet as we announce the 2022 Love Stories TV Award Winners during a virtual
              celebration honoring the breathtaking work of the filmmaker industry. Don&apos;t miss surprise guests and
              prizing throughout the event.
            </p>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FontAwesomeIcon icon={faCalendar} />
                <div style={{ marginLeft: 10 }}>April 7th, 2022 at 7:00pm EST</div>
              </div>
              <CtaLink
                to="https://www.bigmarker.com/love-stories-tv/2022-Wedding-Film-Awards"
                $colorScheme={CtaColorScheme.Dark}
                caption="RSVP"
              />
            </div>
          </InvitationCard>

          <InvitationCard>
            <Typography variant="h3">Love Stories TV Awards Watch Party Kit</Typography>
            <p style={{ margin: '25px 0' }}>
              Get ready to join the party by indulging in some of our favorite celebration essentials. Download your
              party kit now.
            </p>
            <CtaLink
              to="https://cdn.lovestoriestv.com/ext/wfa/2022/lstv-wfa-2022-watch-party-kit.pdf"
              $colorScheme={CtaColorScheme.Dark}
              caption="Download PDF"
            />
          </InvitationCard>
        </InvitationCardHolder>
      </InvitationContainer>   */}


      <ContentContainer>

        {/* <WinnerVideoContainer>
        <Video media_id="gDot60Yl" type="jwplayer" withPreRoll={false} />
      </WinnerVideoContainer> */}


        <ScrollIntoViewContext.Provider
          value={(el) =>
            window.scroll({
              // Subtract the heights of the page header.
              top: el.offsetTop - 75 - 76,
              behavior: 'smooth',
            })
          }
        >

          <ScrollTabs>
            <div>
              <BreakOutContainer style={{ zIndex: 1, position: 'sticky', top: '75px' }} backgroundColor={theme.white}>
                <StyledTabButtonsControl {...{ tabs }} />
              </BreakOutContainer>
              <TabPanel tabId='categories'>
                <Typography variant='h2' marginTop='30px'>
                  Categories
                </Typography>
                <CategoriesContainer>
                  {activeCompetition.categories.map((category) => (
                    <Category key={category.id} {...{ category, ctaUrl }} />
                  ))}
                </CategoriesContainer>
              </TabPanel>
              <About>
                <AboutBody>
                  <Typography variant='h2' customStyles='max-width: 525px;'>
                    Win Over $10,000 in Cash!
                  </Typography>
                  <AboutDescription>{activeCompetition.header_paragraph_2}</AboutDescription>
                  <DetailsButton activeCompetition={activeCompetition} />
                </AboutBody>
                <AboutImageOuterContainer>
                  <Image src={aboutImage} layout='fill' objectFit='cover' objectPosition='center' loading='eager' />
                  <div style={{ background: 'rgba(58, 61, 52, 0.8)' }} />
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Media at='xs'>
                      <Badge $size='178px' />
                    </Media>
                    <Media greaterThan='xs'>
                      <Badge $size='303px' />
                    </Media>
                  </div>
                </AboutImageOuterContainer>
              </About>
              <BreakOutContainer backgroundColor={theme.highlight_2_0}>
                <RulesTabPanel tabId='rules'>
                  <Typography variant='h2' marginTop='30px' color={theme.highlight_1_0}>
                    Rules
                  </Typography>
                  <Rules />
                </RulesTabPanel>
              </BreakOutContainer>
              <TabPanel tabId='faq'>
                <Typography variant='h2' marginTop='30px'>
                  FAQ
                </Typography>
                <FaqContainer>
                  <Faq {...{ activeCompetition }} />
                </FaqContainer>
              </TabPanel>
              <TabPanel tabId='terms'>
                <Typography variant='h2' marginTop='30px'>
                  Terms & Conditions
                </Typography>
                <FaqContainer>
                  <Terms />
                </FaqContainer>
              </TabPanel>
              <TabPanel tabId='judges'>
                <Typography variant='h2' marginTop='30px'>
                  Judges
                </Typography>
                <JudgesContainer>
                  <Judges judges={activeCompetition.judges} />
                </JudgesContainer>
              </TabPanel>

              <TabPanel tabId='past-winners'>
                <Typography variant='h2' marginTop='30px'>
                  Past Years Winners
                </Typography>
                <br/>
                <PastWinnersContainer>
                  <a href={"https://lovestoriestv.com/wedding-film-awards/2023/winners"}
                     rel="noreferrer" target={"_blank"}>
                      <img style={{width: '110px'}} src={"https://lstv-cdn.s3.us-east-2.amazonaws.com/ext/wfa/2023/WFA_03_Badge_Primary_02.svg"}/>
                    </a>
                  <a href={"https://lovestoriestv.com/wedding-film-awards/2022/winners"}
                     rel="noreferrer" target={"_blank"}>
                      <img style={{width: '110px'}} src={"https://cdn.lovestoriestv.com/ext/wfa/2022/SVG/WFA_03_Badge_Primary_02.svg"}/>
                    </a>
                  <a href={"https://lovestoriestv.com/wedding-film-awards/2021/winners"}
                     rel="noreferrer" target={"_blank"}>
                    <img style={{width: '110px'}} src={"https://cdn.lovestoriestv.com/ext/wfa/2021/SVG/WFA_2021_03_Badge_Primary_02.svg"}/>
                  </a>
                  <a href={"https://lovestoriestv.com/wedding-film-awards/2020/winners"}
                     rel="noreferrer" target={"_blank"}>
                    <img style={{width: '110px'}} src={"https://cdn.lovestoriestv.com/ext/wfa/2020/SVG/WFA_2020_03_Badge_Primary_02.svg"}/>
                  </a>
                </PastWinnersContainer>
              </TabPanel>

              {seeFinalists && (
                <TabPanel tabId='finalists'>
                  <Typography variant='h2' marginTop='30px'>
                    Finalists
                  </Typography>
                  <CompetitionResults
                    hideHeader={true}
                    awardStatus='finalists'
                    year={activeCompetition.year}
                    competition={activeCompetition}
                  />
                </TabPanel>
              )}
              {seeWinners && (
                <TabPanel tabId='winners'>
                  <Typography variant='h2' marginTop='30px'>
                    Winners
                  </Typography>
                  <CompetitionResults
                    hideHeader={true}
                    awardStatus='winners'
                    year={activeCompetition.year}
                    competition={activeCompetition}
                  />
                </TabPanel>
              )}
            </div>
          </ScrollTabs>
        </ScrollIntoViewContext.Provider>
      </ContentContainer>
    </>
  )
}

export default WfaPage
