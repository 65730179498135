import React from 'react';
import {
  faAlarmClock,
  faAward,
  faCameraMovie,
  faFileCertificate,
  faGramophone,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import Typography from '~/atoms/Typography';
import { minWidth, maxWidth } from '~/styles/media';
import theme from '~/styles/styledComponentsTheme';

const Container = styled.div`
  color: ${theme.highlight_1_0};
  margin-top: 50px;
  display: flex;
  align-items: center;
  @media ${maxWidth('md')} {
    flex-wrap: wrap;
  }
`;

const DesktopSelectors = styled.div`
  display: none;
  @media ${minWidth('sm')} {
    display: flex;
    width: 100%;
  }
  @media ${minWidth('md')} {
    display: block;
    width: 350px;
  }
`;

const DesktopSelector = styled.div<{ $selected: boolean }>`
  border-left: 1px solid ${theme.highlight_1_0};
  padding-left: 18px;
  margin-bottom: 50px;
  max-height: 500px;
  cursor: pointer;
  flex-grow: 1;
  opacity: ${(props) => (props.$selected ? 1 : 0.5)};

  @media ${maxWidth('md')} {
    padding-left: 9px;
  }
`;

const MobileSelectors = styled.div`
  display: flex;
  width: 100%;
  @media ${minWidth('sm')} {
    display: none;
  }
`;

const MobileSelector = styled.div<{ $selected: boolean }>`
  border-top: 1px solid ${theme.highlight_1_0};
  padding-top: 18px;
  cursor: pointer;
  flex-grow: 1;
  margin-top: 20px;
  margin-right: 5%;
  opacity: ${(props) => (props.$selected ? 1 : 0.5)};
`;

const Details = styled.div`
  margin: 30px;
  max-width: 742px;
  flex-grow: 1;

  @media ${minWidth('sm')} and ${maxWidth('md')} {
    display: flex;
  }

  p {
    margin-bottom: 20px;
  }
`;

const Icon = styled.div`
  width: 91px;
  height: 91px;
  margin-bottom: 20px;
  @media ${maxWidth('md')} {
    margin: 40px;
    width: 200px;
    height: 200px;
  }
  @media ${maxWidth('sm')} {
    margin: 0 auto 40px auto;
  }
`;

const DetailText = styled.div`
  @media ${minWidth('sm')} and ${maxWidth('md')} {
    margin-left: 30px;
    max-width: 430px;
  }
`;

const awardsContent = [
  'Please see individual award categories for eligibility requirements.',
  'You will only be eligible for awards by submitting via your personal dashboard on this site, ' +
    'there is no other entry mechanism.',
  'You are able to submit for as many award categories as you would like. ' +
    'You are also able to submit as many films as you would like per category.',
];

const videoContent = [
  'In order to enter a film in any of the awards categories it must meet the following criteria:',
  '1) It must first be published on lovestoriestv.com.',
  '2) The film can represent a wedding from any date but it must have been published on lovestoriestv.com ' +
  'between January 1st, 2024 and March 28th, 2024.',
  '3) Filmmakers must have at least 5 videos on their lovestoriestv.com channel by ' +
    'March 28th, 2024 for their entries to be eligible in the awards',
];

const finalistContent = [
  'At the end of the submission period, the Love Stories TV team will determine the finalists in each category. ' +
    'Winners are either chosen by judges or by a vote among our filmmaker community ' +
    "(Filmmaker of the Year, Film of the Year) or the public (People's Choice). " +
    'See additional voting details under each award category.',
];

const submissionContent = [
  'The submission deadline for all entries is no later than March 28th, 2024 at 11:59pm EST.',
];


// winner_tag
// 2023 Winner:


const winnersContent = [
  'Once the finalists have been determined, our panel of judges will select the winners in the Best Social Edit, Best International Film, Best New Filmmaker, Best Elopement, Best Fashion, Best LGBTQ+ Wedding, Best Music, Best Moment, Best Cinematography, Best Speech or Vows, Best Venue, Best South Asian Wedding, Best Proposal or Engagement, and Best Themed Wedding categories.',
  'In the Filmmaker of the Year and Film of the Year categories, the winner is chosen by a vote open to all ' +
  'filmmakers who are published on lovestoriestv.com. The film with the most votes wins.',
  "In our People's Choice and Wedding of the Year categories, the winner is determined by a vote open to the public. The film with the most votes wins.",
];

const options = [
  { number: '01', title: 'Awards Eligibility', icon: faGramophone, content: awardsContent },
  { number: '02', title: 'Video Eligibility', icon: faCameraMovie, content: videoContent },
  { number: '03', title: 'Finalists Determined', icon: faAward, content: finalistContent },
  { number: '04', title: 'Submission Deadline', icon: faAlarmClock, content: submissionContent },
  { number: '05', title: "Winner's Selection", icon: faFileCertificate, content: winnersContent },
];

const Rules = () => {
  const [option, setOption] = React.useState(0);
  return (
    <Container>
      <DesktopSelectors>
        {options.map((opt, ndx) => (
          <DesktopSelector key={opt.number} onClick={() => setOption(ndx)} $selected={ndx === option}>
            <Typography variant="h5">{opt.number}</Typography>
            <Typography variant="h5" marginTop="6px">
              {opt.title}
            </Typography>
          </DesktopSelector>
        ))}
      </DesktopSelectors>
      <Details>
        <Icon>
          <FontAwesomeIcon icon={options[option].icon} style={{ width: '100%', height: '100%' }} />
        </Icon>
        <DetailText>
          <Typography variant="h2" marginBottom="25px">
            {options[option].title}
          </Typography>
          {options[option].content.map((c, ndx) => (
            <p key={`${option}-${ndx}`}>{c}</p>
          ))}
        </DetailText>
      </Details>
      <MobileSelectors>
        {options.map((opt, ndx) => (
          <MobileSelector key={opt.number} onClick={() => setOption(ndx)} $selected={ndx === option}>
            <Typography variant="h5">{opt.number}</Typography>
          </MobileSelector>
        ))}
      </MobileSelectors>
    </Container>
  );
};

export default Rules;
