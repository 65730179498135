import { faUsdCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICategory } from '@lstv/core/types';
import React from 'react';
import styled from 'styled-components';
import { CtaColorScheme, CtaLink } from '~/components/cta/regular';
import useReadMoreButton from '~/components/useReadMoreButton';
import { maxWidth, Media, minWidth } from '~/styles/media';

const Header = styled.div`
  display: flex;

  @media ${maxWidth('md')} {
    flex-direction: column-reverse;
  }

  @media ${minWidth('md')} {
    align-items: center;
  }
`;

const TitleContainer = styled.div`
  font-size: 30px;
  line-height: 35.7px;

  @media ${maxWidth('md')} {
    margin-top: 6px;
  }
`;

const SubtitleContainer = styled.div`
  font-size: 17px;
  line-height: 21.15px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  font-size: 15px;
  line-height: 17.85px;
  color: ${(props) => props.theme.highlight_2_0};

  @media ${maxWidth('sm')} {
    min-height: 160px;
  }

  @media ${minWidth('sm')} and ${maxWidth('md')} {
    min-height: 125px;
  }

  @media ${minWidth('md')} {
    min-height: 72px;
    justify-content: center;
  }
`;

const CategoryContent = styled.div`
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
`;

const CategoryContentSection = styled.section`
  padding: 10px 0;

  &:first-child {
    margin-top: 20px;
  }
`;

const CategoryContentHeading = styled.div`
  color: #000;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 6px;
`;

const CategoryContentText = styled.section`
  font-size: 15px;
  color: ${(props) => props.theme.grey_1_0};
  max-width: 520px;
`;

const Footer = styled.div`
  margin-top: 20px;
  display: flex;

  @media ${maxWidth('sm')} {
    flex-direction: column;
  }

  @media ${minWidth('sm')} {
    align-items: center;
    justify-content: space-between;
  }
`;

const CategoryContainer = styled.div<{ $containerHeight: number }>`
  border-radius: 5px;
  background: ${(props) => props.theme.grey_6_0};

  ${CategoryContent} {
    height: ${(props) => props.$containerHeight}px;
  }

  @media ${minWidth('sm')} {
    padding: 27px 17px;
  }

  @media ${maxWidth('sm')} {
    padding: 36px 20px;
  }
`;

const Category = ({ category, ctaUrl }: { category: ICategory; ctaUrl: string }) => {
  const { containerHeight, setContainerEl, readMoreButton } = useReadMoreButton('More Info');
  return (
    <CategoryContainer $containerHeight={containerHeight}>
      <Header>
        <div style={{ flex: '1 1 0' }}>
          <TitleContainer>{category.name}</TitleContainer>
          <SubtitleContainer>
            <FontAwesomeIcon icon={faUsdCircle} style={{ marginRight: '6px' }} />
            Winner Prize: {category.prize}
          </SubtitleContainer>
        </div>
        <img src={category.category_image} width="92" height="92" />
      </Header>
      <DescriptionContainer>{category.description}</DescriptionContainer>
      <Footer>
        <div></div>
        <Media at="xs">
          <div style={{ marginTop: '20px' }} />
        </Media>
        {readMoreButton}
      </Footer>
      <CategoryContent ref={setContainerEl}>
        <CategoryContentSection>
          <CategoryContentHeading>Eligibility</CategoryContentHeading>
          <CategoryContentText>{category.eligibility}</CategoryContentText>
        </CategoryContentSection>
        <CategoryContentSection>
          <CategoryContentHeading>Voting</CategoryContentHeading>
          <CategoryContentText>{category.voting}</CategoryContentText>
        </CategoryContentSection>
        <CategoryContentSection>
          <CategoryContentHeading>Selection Criteria</CategoryContentHeading>
          <CategoryContentText>{category.selection_criteria}</CategoryContentText>
        </CategoryContentSection>
      </CategoryContent>
    </CategoryContainer>
  );
};

export default Category;
